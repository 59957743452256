import React from "react"

export const Logo = () => {
  return (
    <svg
      width="133"
      height="24"
      viewBox="0 0 133 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0)">
        <path
          d="M79.4715 19.7822H77.591V24.0012H79.4715V19.7822Z"
          fill="black"
        />
        <path
          d="M74.8371 18.9147L73.3127 22.8486L75.0662 23.528L76.5905 19.5941L74.8371 18.9147Z"
          fill="black"
        />
        <path
          d="M72.5863 17.1169L69.7441 20.2349L71.1339 21.5016L73.9761 18.3837L72.5863 17.1169Z"
          fill="black"
        />
        <path
          d="M71.1325 14.621L67.3559 16.5015L68.194 18.1848L71.9707 16.3043L71.1325 14.621Z"
          fill="black"
        />
        <path
          d="M70.68 11.7724L66.479 12.1614L66.6523 14.0338L70.8534 13.6448L70.68 11.7724Z"
          fill="black"
        />
        <path
          d="M67.2282 7.79019L66.7136 9.59888L70.7716 10.7533L71.2861 8.94459L67.2282 7.79019Z"
          fill="black"
        />
        <path
          d="M69.5076 3.99157L68.3744 5.49219L71.7411 8.03474L72.8744 6.53413L69.5076 3.99157Z"
          fill="black"
        />
        <path
          d="M73.0078 1.27875L71.4089 2.26855L73.6296 5.85576L75.2285 4.86596L73.0078 1.27875Z"
          fill="black"
        />
        <path
          d="M77.2469 -0.0023282L75.3985 0.343262L76.1739 4.49035L78.0223 4.14476L77.2469 -0.0023282Z"
          fill="black"
        />
        <path
          d="M79.818 0.00050114L79.042 4.14746L80.8903 4.49337L81.6664 0.346414L79.818 0.00050114Z"
          fill="black"
        />
        <path
          d="M84.0518 1.26997L81.831 4.85718L83.4299 5.84698L85.6507 2.25978L84.0518 1.26997Z"
          fill="black"
        />
        <path
          d="M87.557 3.99627L84.1902 6.53882L85.3235 8.03943L88.6902 5.49688L87.557 3.99627Z"
          fill="black"
        />
        <path
          d="M89.635 8.09917L85.5848 9.23828L86.0926 11.0435L90.1428 9.9044L89.635 8.09917Z"
          fill="black"
        />
        <path
          d="M86.3775 11.7662L86.2041 13.6387L90.4051 14.0276L90.5785 12.1552L86.3775 11.7662Z"
          fill="black"
        />
        <path
          d="M85.9291 14.616L85.0909 16.2993L88.8675 18.1798L89.7057 16.4965L85.9291 14.616Z"
          fill="black"
        />
        <path
          d="M84.4817 17.1168L83.092 18.3835L85.9341 21.5015L87.3239 20.2347L84.4817 17.1168Z"
          fill="black"
        />
        <path
          d="M82.2234 18.9097L80.47 19.5891L81.9943 23.5231L83.7477 22.8437L82.2234 18.9097Z"
          fill="black"
        />
        <path
          d="M15.1192 17.9088V22.7147H0V0.86792H14.9631V5.67376H4.99711V9.29777H12.3367V14.0412H4.99409V17.9088H15.1192Z"
          fill="black"
        />
        <path
          d="M36.0438 8.35552C36.0438 12.506 32.7047 15.8451 28.3931 15.8451H24.1963V22.7116H19.2022V0.86792H28.3971C32.7047 0.86792 36.0438 4.20704 36.0438 8.35552ZM31.0507 8.35552C31.0507 6.76297 29.9263 5.54591 28.3971 5.54591H24.1963V11.1641H28.3971C29.9263 11.1671 31.0507 9.95008 31.0507 8.35552Z"
          fill="black"
        />
        <path
          d="M44.1837 0.86792V22.7126H39.1906V0.86792H44.1837Z"
          fill="black"
        />
        <path
          d="M65.5583 5.67376H59.015V22.7147H54.0229V5.67376H47.4796V0.86792H65.5583V5.67376Z"
          fill="black"
        />
        <path
          d="M114.419 22.7147H109.425V10.0125L103.776 19.2819H103.214L97.5654 10.0125V22.7147H92.5713V0.86792H97.5654L103.495 10.5742L109.425 0.86792H114.419V22.7147Z"
          fill="black"
        />
        <path
          d="M132.802 17.9088V22.7147H117.68V0.86792H132.643V5.67376H122.677V9.29777H130.479V14.0412H122.677V17.9088H132.802Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="132.802" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
