import { gsap } from "gsap"
import { SplitText } from "gsap/SplitText"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import CustomEase from "gsap/CustomEase"

const textAppearAnimationAll = (name) => {
  const elm = document.querySelectorAll(name);
  elm.forEach((controls, e) => {
    if(elm[e].classList.contains('appear') === false) {
      let className = name.replaceAll(".", "") + '_' + (Math.random() + 1).toString(36).substring(7);
      elm[e].classList.add(className);
      elm[e].classList.add('appear');
      textAppearAnimation(`.${className}`);
    }
  });
}

const textAppearAnimation = (name) => {

  //console.log('textAppearAnimation: ' + name);

  gsap.registerPlugin(SplitText, ScrollTrigger, CustomEase);

  const childSplit = new SplitText(name , {
    type: "lines",
    linesClass: "split-child"
  });

  new SplitText(name, {
    linesClass: "split-parent"
  });

  ScrollTrigger.create({
    trigger: childSplit.lines,
    start: "top bottom+=100",
    once: true,
    onEnter: () => {
      gsap.set(childSplit.lines, {
        opacity: 1
      });
      gsap.from(childSplit.lines, {
        duration: 0.75,
        yPercent: 100,
        opacity: 0,
        ease: CustomEase.create("custom", "M0,0 C0,0 0.03386,0.1654 0.06282,0.26814 0.0873,0.35495 0.10382,0.40627 0.13772,0.48848 0.16554,0.55594 0.18587,0.59821 0.22396,0.65889 0.25792,0.71299 0.28469,0.74834 0.32888,0.793 0.36911,0.83366 0.40199,0.85936 0.45107,0.88843 0.502,0.9186 0.54119,0.93506 0.59853,0.95274 0.66304,0.97262 0.70743,0.98051 0.77593,0.98857 0.86085,0.99857 1,1 1,1"),
        stagger: 0.2,
        delay: 0.4
      });
    }
  });
}

export {textAppearAnimation, textAppearAnimationAll}
