/**
 * Seo component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

import FontKosticItalic from '../css/fonts/KosticMongooseItalic.otf'
import FontKosticMedium from '../css/fonts/KosticMongooseMedium.otf'
import HelveticaNeueRegular from '../css/fonts/HelveticaNeue-Regular.otf';
import HelveticaNeueBold from '../css/fonts/HelveticaNeue-Bold.otf';

const Seo = ({ description, lang, meta, title, slug, extendedTitle=true }) => {
  const { wp, wpUser } = useStaticQuery(
    graphql`
      query {
        wp {
          generalSettings {
            title
            description
          }
        }

        # if there's more than one user this would need to be filtered to the main user
        wpUser {
          twitter: name
        }
      }
    `
  )

  const metaDescription = description? description : wp.generalSettings?.description
  const defaultTitle = wp.generalSettings?.title
  const url = "https://epitomecollective.com/"+slug
  //const url = typeof window !== 'undefined' ? window.location.href : '';
  let titleTemplate;

  if (extendedTitle==true) {
    // titleTemplate= title + ' | ' + defaultTitle;
    titleTemplate= title + ' | Epitome Collective'
  } else {
    titleTemplate= title
  }

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
     //titleTemplate={defaultTitle ? `%s | ${defaultTitle}` : null}
      titleTemplate={titleTemplate}
    >

      <meta name="description" content={metaDescription} />
      <meta name="image" content="https://epitomecollective.com/ogimage.png" />
      {/*<meta property="og:url" content={this.props.location} />}*/}
      {/*<meta property="og:type" content="article" />*/}
      <meta property="og:title" content={defaultTitle} />
      <meta property="og:description" content={metaDescription} />
      <meta property="og:type" content="website" />
      <meta property="og:image" content="https://epitomecollective.com/ogimage.png" />

      <meta name="twitter:card" content="summary" />
      <meta name="twitter:creator" content={wpUser?.twitter || ``} />
      <meta name="twitter:title" content={defaultTitle} />
      <meta name="twitter:description" content={metaDescription} />
      <meta name="twitter:image" content="https://epitomecollective.com/ogimage.png" />
      
      <link rel="alternate" href={url} hreflang="en-sg" />
      <link rel="alternate" href={url} hreflang="x-default" />

      <link rel="preload"
            as="font"
            href={FontKosticItalic}
            type="font/otf"
            crossOrigin="anonymous" />

      <link rel="preload"
            as="font"
            href={FontKosticMedium}
            type="font/otf"
            crossOrigin="anonymous" />

      <link rel="preload"
            as="font"
            href={HelveticaNeueRegular}
            type="font/otf"
            crossOrigin="anonymous" />

      <link rel="preload"
            as="font"
            href={HelveticaNeueBold}
            type="font/otf"
            crossOrigin="anonymous" />

      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href="/icons/apple-touch-icon.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href="/icons/favicon-32x32.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href="/icons/favicon-16x16.png"
      />
      <link rel="manifest" href="/site.webmanifest" />
      <link rel='mask-icon' href='/icons/icon.svg' color='black' />
      <link rel="icon" href="/icons/icon.svg" />
      <meta name="msapplication-TileColor" content="#ffffff" />
      <meta name="theme-color" content="#ffffff" />
      <noscript>{`
        <style type="text/css">
          .container.padding-bottom-200 p {
              opacity: 1 !important;
          }
       </style>`}
      </noscript>
    </Helmet>
  )
}

Seo.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

Seo.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired
}

export default Seo
