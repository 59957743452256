import React, { useEffect, useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Logo } from "./svg/logo"
import { Facebook, Instagram, LinkedIn, Vimeo } from "./social"


import { gsap } from "gsap"
import { SplitText } from "gsap/SplitText"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import AniLink from 'gatsby-plugin-transition-link/AniLink'
import { Linear } from "gsap/gsap-core"
import CustomEase from "gsap/CustomEase"

import { createPopup } from '@typeform/embed'
import '@typeform/embed/build/css/popup.css'

// let assetsLoaded = false;

const flatListToHierarchical = (
    data = [],
    {idKey='id',parentKey='parentId',childrenKey='children'} = {}
) => {
    const tree = [];
    const childrenOf = {};
    data.forEach((item) => {
        const newItem = {...item};
       // const { [idKey]: idKey, [parentKey]: parentId = 0 } = newItem;
        childrenOf[item.id] = childrenOf[item.id] || [];
        newItem[childrenKey] = childrenOf[item.id];    
          item.parentId
            ? (
                childrenOf[item.parentId] = childrenOf[item.parentId] || []
            ).push(newItem)
            : tree.push(newItem);
    });
    return tree;
};


const { toggle } = createPopup("t6Vp86Cd", {
  onSubmit: ({ formId, responseId }) => {
    console.log(`Form ${formId} submitted, response id: ${responseId}`);
    window.dataLayer.push({event: 'form_submitted'});
  }
});

const Layout = ({ isHomePage, children, page }) => {

  const [menuIsOpened, setMenuOpened] = useState(false);

  const toggleMenu = () => {
    if(menuIsOpened){
      setMenuOpened(false);
      setTimeout(() => {
        document.querySelector("body").classList.remove('mobile-opened');
      }, 1);
    } else {
      setMenuOpened(true);
      setTimeout(() => {
        document.querySelector("body").classList.add('mobile-opened');
      }, 1);
    }
  }

  useEffect(() => {

    document.querySelector("body").classList.remove('mobile-opened');

    window.scrollTo(0,0);

    if(gsap) {

      gsap.registerPlugin(SplitText, ScrollTrigger, CustomEase);
    //
    //   if(!assetsLoaded) {
    //     let loadingScreen = document.querySelector('#loading');
    //     if (loadingScreen) {
    //       gsap.to(loadingScreen, {
    //         autoAlpha: 0,
    //         ease: "power4",
    //         duration: 1,
    //         onComplete: () => {
    //           assetsLoaded = true;
    //
    //           let ld = document.querySelector("#loading");
    //           if(ld) {
    //             console.log('delete');
    //             console.log(ld);
    //             // ld.remove();
    //           }
    //
    //         }
    //       })
    //     }
    //   }

      // navigation
      const navAnimation = gsap.from(".navbar", {
        yPercent: -100,
        ease: "none",
        paused: true,
        duration: 0.15
      }).progress(1);

      ScrollTrigger.create({
        start: "100 top",
        end: 99999,
        onUpdate: (self) => {
          self.direction === -1 ? navAnimation.play() : navAnimation.reverse()
        }
      });

      // paragraphs
      gsap.utils.toArray("p").forEach((element, i) => {
        gsap.set(element, { y: 100, autoAlpha: 0 });
        ScrollTrigger.create({
          trigger: element,
          start: "top bottom",
          once: true,
          onEnter: () => {
            gsap.to(element, {
              y: 0,
              ease: CustomEase.create("custom", "M0,0 C0,0 0.03386,0.1654 0.06282,0.26814 0.0873,0.35495 0.10382,0.40627 0.13772,0.48848 0.16554,0.55594 0.18587,0.59821 0.22396,0.65889 0.25792,0.71299 0.28469,0.74834 0.32888,0.793 0.36911,0.83366 0.40199,0.85936 0.45107,0.88843 0.502,0.9186 0.54119,0.93506 0.59853,0.95274 0.66304,0.97262 0.70743,0.98051 0.77593,0.98857 0.86085,0.99857 1,1 1,1"),
              autoAlpha: 1,
              duration: 0.5
            });
          }
        });
      });

      // section-up
      gsap.utils.toArray(".section-up").forEach((element, i) => {
        //console.log(element);
        gsap.set(element, { y: 100, autoAlpha: 0 });
        ScrollTrigger.create({
          trigger: element,
          start: "top bottom",
          once: true,
          onEnter: () => {
            gsap.to(element, {
              y: 0,
              ease: CustomEase.create("custom", "M0,0 C0,0 0.03386,0.1654 0.06282,0.26814 0.0873,0.35495 0.10382,0.40627 0.13772,0.48848 0.16554,0.55594 0.18587,0.59821 0.22396,0.65889 0.25792,0.71299 0.28469,0.74834 0.32888,0.793 0.36911,0.83366 0.40199,0.85936 0.45107,0.88843 0.502,0.9186 0.54119,0.93506 0.59853,0.95274 0.66304,0.97262 0.70743,0.98051 0.77593,0.98857 0.86085,0.99857 1,1 1,1"),
              autoAlpha: 1,
              duration: 0.5
            });
          }
        });
      });

      // marque
      gsap.utils.toArray(".rollingText").forEach((text, i) => {
        var width = text.offsetWidth;
        var tl = gsap.timeline();
        let speed = parseInt(text.dataset.rollingSpeed);
        let way = text.dataset.rollingWay;
        tl.to(text, speed, {
          x: (way === "left" ? -width : +width),
          ease: Linear.easeNone,
          repeat: -1,
        });
        // var clone = text.cloneNode(true);
        // text.append(clone);
      });

      // scroller text
      gsap.utils.toArray('.wrapperScrollText').forEach((section, index) => {

        let firstLineSpeed = 12; // less = faster
        let firstLineStartPosition = "0"; // from 0 to 100%
        let secondLineSpeed = 24;
        let secondLineEndPosition = "0";
        let scrub = 0.3; // easing

        const w = section.querySelector('.text');
        const [x, xEnd] = (index % 2) ? [secondLineEndPosition, (w.scrollWidth/secondLineSpeed) * -1] : [(w.scrollWidth/firstLineSpeed) * -1, firstLineStartPosition];
        gsap.fromTo(w, {  x  }, {
          x: xEnd,
          scrollTrigger: {
            trigger: section,
            scrub: scrub,
          }
        });
      });
    }

  }, [])

  const menuQuery = useStaticQuery(graphql`
    query MyQuery {
      wpMenu(name: { eq: "Primary" }) {
        name
        menuItems {
          nodes {
            id
            parentId
            url
            label
          
          }
        }
      }
    }
  `)

  let mainClass = "global-wrapper ";
  if(page) mainClass += `page-${page} `;

  const menu = menuQuery.wpMenu.menuItems.nodes;

  const hierarchicalList = flatListToHierarchical( menuQuery.wpMenu.menuItems.nodes, {
    idKey: "id",
    childrenKey: "routes",
    parentKey: "parent",
  });

  let headerClass =  "global-header full-width navbar ";
  // if(menuIsOpened) {
  //   headerClass +=  "mobile-opened ";
  // }

  return (
    <div className={mainClass} data-is-root-path={isHomePage}>
      <header className={headerClass}>
        <div className="wrapper">
          <AniLink cover bg="#000" direction="up" duration={1} className="header-link-home" to="/">
            <Logo />
          </AniLink>
          <div className="separator"/>
          <button className="toggle" onClick={() => toggleMenu()}>{menuIsOpened ? "Close" : "Menu"}</button>
          <ul className="nav">
            {hierarchicalList.map((item, key) => {
             // if (item.url !== '/blog/' ) {
              if (1) {
                return (
                  <li key={key}>
                    <AniLink cover to={item.url} bg="#000" direction="up" duration={1}>
                      {item.label}
                    </AniLink>

        {item.routes.length>0 ? (
        <>
        <ul>     
          {item.routes.map((item, key) => {
            return(<><li key={key}><AniLink cover to={item.url} bg="#000" direction="up" duration={1}>{item.label}</AniLink></li></>)
          })}
        </ul>
        </>): null
        }

                  </li>
                )
              } 
            })}
            <li>
              <a className="contact-alink" href={"/"} onClick={(e) => {toggle(); e.preventDefault(); console.log('show contact form'); return false}} onKeyDown={(e) => {toggle(); e.preventDefault(); return false}}>Contact</a>
            </li>
          </ul>
        </div>
      </header>
      <main>{children}</main>
      <section className={"connect"}>
        <div className="wrapper">
          <div className="container">
            <h2>
              GOT A PROJECT?<br/>
              <a href={"/"} onClick={(e) => {toggle(); e.preventDefault(); console.log('show contact project'); return false}} onKeyDown={(e) => {toggle(); e.preventDefault(); return false}}>LET'S CONNECT.</a>
            </h2>
          </div>
        </div>
      </section>
      <footer className="full-width">
        <div className="wrapper">
          <div className="logo">
            <Logo />
          </div>
          <div className="address-name">
            <span>
              Epitome Productions PTE LTD
              <br />
              201627385R
            </span>
          </div>
          <div className="address-city">
            <span>
              3 Kim Chuan Lane, Goodland Group Building
              <br />
              #05-01 Singapore 537069
            </span>
          </div>
          <div className="address-contact">
            <span>
              <a href="mailto:info@epitomecollective.com">
                info@epitomecollective.com
              </a>
              <br />
              <a href="tel:+6591700616">+65 91700616</a>
            </span>
          </div>
          <div className="separator"/>
          <div className="social">
            <a href="https://www.facebook.com/epitomecollective" target={"_blank" }  rel="noreferrer">
              <Facebook />
            </a>
            <a href="https://www.instagram.com/epitome_collective/" target={"_blank"}  rel="noreferrer">
              <Instagram />
            </a>
            <a href="https://www.linkedin.com/company/epitome-collective/" target={"_blank"}  rel="noreferrer">
              <LinkedIn />
            </a>
            <a href="https://vimeo.com/epitomecollective" target={"_blank"} rel="noreferrer">
              <Vimeo />
            </a>
          </div>
          <div className="break"/>
          <div className="policy">
            <span>
              Privacy Policy
              <br />
              All Rights Reserved © 2021
            </span>
          </div>
        </div>
      </footer>
    </div>
  )
}

export default Layout
